<template>
  <div
    class="hit-table-root bg-table rounded border border-table"
    :style="{'max-height': customMaxHeight}"
  >
    <div
      v-if="largeEnoughForGrid || !workInProgress"
      :class="{
        'hit-table-menu': largeEnoughForGrid,
        'hit-table-menu-mobile':
          !largeEnoughForGrid && !workInProgress && !forceRemoveSort,
        'only-one-row': !largeEnoughForGrid && !searchable && !showFilter,
      }"
    >
      <div
        class="hit-table-menu-left"
        :class="{
          'w-full': !largeEnoughForGrid,
          'w-1/3': largeEnoughForGrid,
        }"
      >
        <hit-search
          v-if="searchable || forceSearch"
          :value="searchValue"
          :border-visible="false"
          :shadow-visible="false"
          :placeholder="t('hit-components.common.search')"
          :auto-complete-id="autoCompleteId"
          :instant-focus="instantSearch"
          @pressEnter="searchValueChanged"
          @clearInput="searchValueChanged"
          @blur="searchValueChanged"
          @keyup.enter.stop
          @keydown.enter.stop
        />
      </div>
      <div class="hit-table-menu-right">
        <hit-dropdown
          v-if="showFilter"
          icon="filter"
          :label="
            responsiveBreakpointSm
              ? t('hit-components.common.filters', 2)
              : null
          "
          :remove-padding="true"
          button-color="table-light"
          button-gap-size="none"
          :hide-border="true"
          @click="notImplementedYet()"
        />
        <hit-icon
          v-if="!disableHideColumns && largeEnoughForGrid"
          icon="advanced_settings"
          :clickable="true"
          color="text-table-light"
          @click="openColumnsToDisplayDialog"
        />
        <hit-icon
          v-if="hasActiveFilters && largeEnoughForGrid"
          icon="no_filter"
          :clickable="true"
          color="text-table-light"
          @click="removeAllFilters"
        />
        <hit-icon
          v-if="userStore && largeEnoughForGrid"
          icon="filter"
          :clickable="true"
          color="text-table-light"
          @click="displayFilterConfigModal"
        />
      </div>
      <div
        v-if="!largeEnoughForGrid && !workInProgress && !forceRemoveSort"
        class="w-full hit-table-menu-bottom items-center"
      >
        <hit-icon icon="sort" />
        <hit-select
          :options="sortableProperties"
          :value="sort.property"
          value-property="propertyName"
          class="inline-block"
          :enable-placeholder="false"
          :label-fields="['header']"
          item-id-property="propertyName"
          :enable-clear="false"
          :adapt-to-content="false"
          @change="sort.property = $event"
        >
          <template #optionLabel="{item}">
            {{ item.header }}
          </template>
        </hit-select>

        <hit-icon
          :clickable="true"
          :icon="sortIcon"
          :tooltip="t('hit-components.common.sort-tooltip')"
          @click="toggleSortOrder"
        />
      </div>
    </div>
    <hit-table-data-list
      v-if="!largeEnoughForGrid"
      :work-in-progress="workInProgress"
      :form-item="itemBeingEdited ? itemBeingEdited : newItem"
      :validations="validations"
      :item-properties-to-display="itemPropertiesToDisplay"
      :tab-lines-to-display="tabLinesToDisplay"
      :items-selectable="itemsSelectable"
      :auto-save="autoSave"
      :item-id-property="itemIdProperty"
      :data-list-config="dataListConfig"
      :item-being-created="itemBeingCreated"
      :selected-items="selectedItems"
      :dynamic-card="dynamicCard"
      :before-save-fn="beforeSaveFn"
      :second-zone-bold="secondZoneBold"
      :deletable="deletable"
      :data-service="dataService"
      @save="(value) => $emit('save', value)"
      @saveLine="(value) => $emit('saveLine', value)"
      @editingItem="(value) => $emit('edit-item', value)"
      @insertLine="(value) => $emit('insertLine', value)"
      @deleteItem="
        (value) => {
          selectedItems = [value];
          deleteItems();
        }
      "
    >
      <template
        v-for="({}, slot) of $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </hit-table-data-list>
    <hit-table-data-grid
      v-if="largeEnoughForGrid"
      :work-in-progress="workInProgress"
      :item-being-edited="itemBeingEdited"
      :new-item="newItem"
      :validations="validations"
      :item-properties-to-display="itemPropertiesToDisplay"
      :tab-lines-to-display="tabLinesToDisplay"
      :items-selectable="itemsSelectable"
      :is-loading="isLoading"
      :headers="headers"
      :sort="sort"
      :selected-items="selectedItems"
      :tab-lines="tabLines"
      :item-being-created="itemBeingCreated"
      :auto-save="autoSave"
      :auto-row-height="autoRowHeight"
      :item-id-property="itemIdProperty"
      :dragged-item="draggedItem"
      :draggable="draggable"
      :additional-line="additionalLine"
      :custom-column-width="customColumnWidth"
      :custom-column-alignment="customColumnAlignment"
      :before-save-fn="beforeSaveFn"
      :inline-edition-allowed="inlineEditionAllowed"
      :user-filters="userFilters"
      :data-service="dataService"
      @sort="sort = $event"
      @save="(value) => $emit('save', value)"
      @save-line="(value) => $emit('saveLine', value)"
      @insert-line="(value) => $emit('insertLine', value)"
      @move="
        (draggedItem, droppedOnItem) =>
          $emit('move', draggedItem, droppedOnItem)
      "
      @editing-item="(value) => $emit('edit-item', value)"
      @change-filter="(filters) => $emit('changeFilter', filters)"
      @unselect-all="selectedItems.length = 0"
      @after-change-value="
        (prop, data) => $emit('afterChangeValue', prop, data)
      "
    >
      <template
        v-for="({}, slot) of $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </hit-table-data-grid>
    <div
      v-if="largeEnoughForGrid || !(itemBeingEdited || itemBeingCreated)"
      class="border-t border-table flex flex-wrap items-center justify-between gap-2 p-2"
    >
      <div class="hit-table-footer-left flex-grow">
        <!-- @slot Add button slot -->
        <slot
          v-if="addButtonVisible"
          name="buttonAdd"
          :disabled="addButtonDisabled"
          :add-new-item-fn="addNewItem"
        >
          <hit-button-add
            :label="addButtonLabel"
            :disabled="addButtonDisabled"
            :prefix-icon="addButtonIcon"
            @click="addNewItem"
          />
        </slot>
        <hit-button-print
          v-if="hasSelectedItems && itemsPrintable"
          :label="t('hit-base.common.print')"
          @click="printItems"
        />
        <hit-button-delete
          v-if="deletable && hasSelectedItems"
          :disabled="!itemsDeletable"
          :label="t('hit-components.common.delete')"
          :tooltip="t('hit-components.common.delete-button-tooltip')"
          @delete="deleteItems"
        />
        <hit-button
          v-if="sharable && hasSelectedItems"
          label="Share"
          prefix-icon="share"
          color="accent"
          @click="shareItems"
        />
        <slot
          name="custom-buttons"
          :selected-items="selectedItems"
        />
      </div>
      <div class="ml-auto">
        <!-- @slot Right footer slot -->
        <slot
          v-if="paginator"
          name="pagination"
        >
          <hit-icon
            icon="expand-left"
            :clickable="currentPage > 0"
            @click="currentPage--"
          />
          <span>{{ currentPage + 1 + '/' + noPages }}</span>
          <hit-icon
            icon="expand-right"
            :clickable="currentPage + 1 < noPages"
            @click="currentPage++"
          />
        </slot>
      </div>
    </div>
    <hit-dialog
      v-if="showWIPDialog"
      :visible.sync="showWIPDialog"
      :title="t('hit-components.common.work-in-progress')"
      color="warning"
      :message="t('hit-components.common.work-in-progress-dialog-message')"
      ok-button-color="accent"
      @cancel="showWIPDialog = false"
    >
      <template #footer>
        <hit-button
          prefix-icon="forward"
          color="accent"
          @click="showWIPDialog = false"
        />
      </template>
    </hit-dialog>
    <hit-dialog
      v-if="showColumnsToDisplay"
      :visible.sync="showColumnsToDisplay"
      :title="t('hit-components.common.columns-to-display')"
      color="panel"
      ok-button-color="accent"
      @cancel="closeColumnsToDisplayDialog"
    >
      <template #body>
        <div
          v-for="itemProperty in itemProperties"
          :key="itemProperty.propertyName"
          class="ml-1"
        >
          <hit-input-checkbox
            v-if="itemProperty.header"
            :disabled="!canHideColumn(itemProperty)"
            :value="displayColumn(itemProperty)"
            :values="itemProperty.header.replace('$linbrk$', '')"
            :remove-padding="true"
            use-custom-height
            @change="
              updateWipColumnsToDisplay($event, itemProperty.propertyName)
            "
          />
        </div>
      </template>

      <template #footer>
        <hit-button
          :label="t('hit-components.common.save')"
          color="accent"
          prefix-icon="save"
          @click="columnsToDisplayDialogSave()"
        />
      </template>
    </hit-dialog>
    <hit-modal
      v-if="filterConfigData !== null"
      prefix-icon="filter"
      :title="t('hit-app.configuration.filter-setting')"
      @click-outside="
        filterConfigData = null;
        filterSaveName = null;
      "
    >
      <div class="flex flex-row gap-2 items-center justify-center w-full">
        <hit-input
          :value="filterSaveName"
          @input="
            (v) => {
              filterSaveName = v;
              filterErrorMessage = null;
            }
          "
        />
        <hit-button
          :label="t('hit-app.configuration.save-actual-filter')"
          prefix-icon="save"
          color="accent"
          :disabled="!hasActiveFilters || !filterSaveName"
          @click="saveActualFilter"
        />
      </div>

      <div
        v-if="filterErrorMessage"
        class="text-danger-icon"
      >
        <span>{{ filterErrorMessage }}</span>
      </div>

      <div class="mt-2">
        <hit-table
          :item-properties="presafeFilterProperties"
          :search-field="null"
          :items="filterConfigData"
          item-id-property="designation"
          items-selectable
          deletable
          @delete="deletePresetFilters"
          @itemSelected="selectFilter"
        />
      </div>
    </hit-modal>
    <slot name="dialogZone" />
  </div>
</template>

<script>
import HitTableDataGrid from './HitTableDataGrid.vue';
import HitTableMixin from '../../mixins/table/HitTableMixin.js';
import HitTableDataList from './HitTableDataList.vue';
import HitBreakpointsMixin from '../../mixins/breakpoints/HitBreakpointsMixin';
import HitLoadingReceiverMixin from '../../mixins/loading/HitLoadingReceiverMixin';
import {useI18n} from 'vue-i18n';
import HitSearch from '../search/HitSearch.vue';
import HitDropdown from '../dropdown/HitDropdown.vue';
import HitIcon from '../icon/HitIcon.vue';
import HitDialog from '../dialog/HitDialog.vue';
import HitButton from '../button/HitButton.vue';
import HitButtonAdd from '../button/HitButtonAdd.vue';
import HitButtonPrint from '../button/HitButtonPrint.vue';
import HitButtonDelete from '../button/HitButtonDelete.vue';
import HitInputCheckbox from '../form/input/HitInputCheckbox.vue';
import HitSelect from '../form/input/HitSelect.vue';
import {inject, nextTick} from 'vue';
import HitModal from '@hit/components/src/components/modal/HitModal.vue';
import HitInput from '../form/input/HitInput.vue';
import {HitUUIDUtils} from '../../utils';

const SORT_ORDER_ASC = 'asc';
const SORT_ORDER_DESC = 'desc';
const SHOW_ALL_COLUMNS_KEY = '$ShowAllColumns$';

function currentFormRef(item_id) {
  return `Item_${item_id}`;
}

function currentHitFormRef(item_id) {
  return `HitForm_${currentFormRef(item_id)}`;
}

function openWIPDialog() {
  // dialog only becomes visible if visible is changed from false to true
  this.showWIPDialog = false;
  nextTick(() => {
    this.showWIPDialog = true;
  });
}

function closeWIPDialog() {
  this.showWIPDialog = false;
}

function retryEditItem() {
  if (this.requestingEditionItemId) {
    this.$nextTick(function () {
      this.editItem(this.requestingEditionItemId);
    });
  }
}

function getItemIdentifier(item) {
  return item[this.itemIdProperty];
}

function getSearchableValues(item) {
  let searchableStrings = [];

  // For each field ('prop.subprop1.subprop2'):
  for (let fieldIndex in this.searchedFields) {
    let searchedFieldPath = this.searchedFields[fieldIndex].split('.');
    let value = item;
    for (let pathIndex in searchedFieldPath) {
      value = value[searchedFieldPath[pathIndex]];
      if (value === null || value === undefined) {
        break;
      }
    }
    if (value !== null && value !== undefined) {
      searchableStrings.push(`${value}`); // add (deep) field value as string if it exists
    }
  }
  return searchableStrings;
}

function lastTabLineItem() {
  const lastTabLine = this.tabLinesToDisplay[this.tabLinesToDisplay.length - 1];

  if (lastTabLine) {
    return lastTabLine.item;
  } else {
    return null;
  }
}

function firstTabLineItem() {
  const firstTabLine = this.tabLinesToDisplay[0];

  if (firstTabLine) {
    return firstTabLine.item;
  } else {
    return null;
  }
}

export default {
  name: 'HitTable',
  components: {
    HitInput,
    HitSearch,
    HitDropdown,
    HitIcon,
    HitDialog,
    HitButton,
    HitButtonAdd,
    HitButtonDelete,
    HitButtonPrint,
    HitSelect,
    HitInputCheckbox,
    HitTableDataGrid,
    HitTableDataList,
    HitModal,
  },
  mixins: [HitTableMixin, HitBreakpointsMixin, HitLoadingReceiverMixin],
  provide() {
    return {
      cancelWIP: this.cancelWIP,
      getValueType: this.getValueType,
      getValueDataObjectCLass: this.getValueDataObjectCLass,
      getLineClass: this.getLineClass,
      editItem: this.editItem,
      openInNewTab: this.openInNewTab,
      selectItem: this.selectItem,
      unselectItem: this.unselectItem,
      generateCustomSlotName: this.generateCustomSlotName,
      disableCreating: this.disableCreating,
      disableEditing: this.disableEditing,
    };
  },
  setup() {
    const largeEnoughForGrid = inject('largeEnoughForGrid');
    const {t} = useI18n();
    return {t, largeEnoughForGrid};
  },
  props: {
    /**
     * Items to display
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * Parent entity ID
     */
    parentId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Enable selection of items
     */
    itemsSelectable: {
      type: Boolean,
      default: false,
    },
    /**
     * When this flag is set to true, a click on the line outside the checkbox also selects the row
     * Can be usefull for screens where we only want to select records
     */
    rowClickToSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Enable paginator
     */
    paginator: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable headers
     */
    headers: {
      type: Boolean,
      default: true,
    },
    /**
     * Enable inline edition
     */
    inlineEditionAllowed: {
      type: Boolean,
      default: false,
    },
    /**
     * Enable automatic row height
     */
    autoRowHeight: {
      type: Boolean,
      default: false,
    },
    /**
     * Item properties to display
     */
    itemProperties: {
      type: Array,
      required: true,
    },
    /**
     * Routerlink to use when clicking on an item
     */
    routerLink: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Sometimes we need to pass params when calling the route
     */
    routerQueryParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    /**
     * Items to display per page
     */
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    /**
     * Default sort of the table
     */
    defaultSort: {
      type: Object,
      default() {
        return {
          property: null,
          order: null,
        };
      },
    },
    /**
     * Store filters
     */
    storeFilters: {
      type: Object,
      default: null,
    },
    /**
     * Enable printing of an item
     */
    printable: {
      type: Boolean,
      default: false,
    },
    /**
     * Function that returns if an item is printable
     */
    printableFn: {
      type: Function,
      default: null,
    },
    /**
     * Function that returns if an item is deletable
     */
    deletableFn: {
      type: Function,
      default: null,
    },
    /**
     * Function to create an item
     */
    createItemFn: {
      type: Function,
      default: null,
    },
    /**
     * Validations for an item creation
     */
    validations: {
      type: Object,
      required: false,
      default: null,
    },
    /**
     * Enable automatic save
     */
    autoSave: {
      type: Boolean,
      required: false,
    },
    /**
     * Enable loading of the table
     */
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Allow to add item
     */
    addItemAllowed: {
      type: Boolean,
      default: true,
    },
    /**
     * Custom classes for every table line
     */
    customLineClass: {
      type: [Function, String],
      default: null,
    },
    /**
     * Origin view route
     */
    originView: {
      type: String,
      default: null,
    },
    /**
     * Allows to delete selected items
     */
    deletable: Boolean,
    /**
     * Allows to add new entries to the table. If false, the "add" button is hidden.
     */
    addable: {
      type: Boolean,
      default: true,
    },
    /**
     * Allows to share the selected records via the share api with another user
     */
    sharable: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Indicates that search events are handled externally.
     */
    customSearch: Boolean,
    /**
     * Indicates that sort events are handled externally.
     */
    customSort: Boolean,
    /**
     * Unique item identifier property (must be unique)
     */
    itemIdProperty: {
      type: String,
      default: 'id',
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoCompleteId: {
      type: String,
      required: false,
      default: 'default',
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    additionalLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    deferSorting: {
      type: Boolean,
      required: false,
      default: false,
    },
    customAddButtonLabel: {
      type: String,
      required: false,
    },
    customAddButtonIcon: {
      type: String,
      required: false,
    },
    customColumnWidth: {
      type: String,
      required: false,
      default: '1fr',
    },
    customColumnAlignment: {
      type: String,
      required: false,
      default: 'end',
      validator: (value) => {
        return ['end', 'center', 'start'].indexOf(value) !== -1;
      },
    },
    configStore: {
      type: Object,
      required: false,
    },
    userStore: {
      type: Object,
      required: false,
    },
    //can contain the fields zone1Field, zone1CustomFunction, zone2Field, ....
    dataListConfig: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    dynamicCard: {
      type: Boolean,
      default: false,
    },
    beforeSaveFn: {
      type: Function,
      default() {
        return null;
      },
    },
    forceSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    customMaxHeight: {
      required: false,
      type: String,
      default: 'calc(100vh - 12.5rem)',
    },
    /**
     * We disable sort for all tables in mobile layout per default
     */
    forceRemoveSort: {
      required: false,
      type: Boolean,
      default: true,
    },
    instantSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableHideColumns: {
      type: Boolean,
      required: false,
      default: false,
    },
    userFilters: {
      type: Object,
      required: false,
      default: () => {},
    },
    searchField: {
      type: String,
      required: false,
      default: 'search',
    },
    secondZoneBold: {
      type: Boolean,
      required: false,
      default: true,
    },
    initialSearchValue: {
      type: String,
      required: false,
      default: '',
    },
    dataService: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      parentDomWrapper: null,
      filterConfigData: null,
      filterErrorMessage: null,
      filterSaveName: '',
      selectedItems: [],
      editingItemId: null,
      requestingEditionItemId: null,
      currentPage: 0,
      sort: {
        property: this.defaultSort.property || this.customSort?.property,
        order: this.defaultSort.order || this.customSort?.order,
      },
      newItem: null,
      wipColumnsToDisplay: [],
      searchValue: null,
      showWIPDialog: false,
      showColumnsToDisplay: false,
      sortOptions: [
        {
          id: SORT_ORDER_ASC,
          name: 'asc',
        },
        {
          id: SORT_ORDER_DESC,
          name: 'desc',
        },
      ],
      newHitFormRef: 'Item_NewItem',
      draggedItem: null,
      finishedMounting: false,
      presafeFilterProperties: [
        {
          propertyName: 'designation',
          header: this.t('hit-base.common.designation'),
        },
      ],
    };
  },
  computed: {
    addButtonLabel() {
      return this.customAddButtonLabel
        ? this.customAddButtonLabel
        : this.t('hit-components.common.add');
    },
    addButtonIcon() {
      return this.customAddButtonIcon ? this.customAddButtonIcon : 'add';
    },
    itemsPrintable() {
      if (this.selectedItems.length > 0) {
        if (this.printableFn) {
          const areItemsPrintable = this.selectedItems.every((item) =>
            this.printableFn(item)
          );

          return this.printable && areItemsPrintable;
        } else {
          return this.printable;
        }
      } else {
        return false;
      }
    },
    itemsDeletable() {
      if (!this.deletable) {
        return false;
      } else if (this.selectedItems.length > 0) {
        if (this.deletableFn) {
          return this.selectedItems.every((item) => this.deletableFn(item));
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    sortIcon() {
      if (this.sort.order === SORT_ORDER_ASC) {
        return 'sort-up';
      } else {
        return 'sort-down';
      }
    },
    sortableProperties() {
      return this.itemProperties.filter((elt) => elt.sortable);
    },
    noPages() {
      return Math.ceil(this.tabLines.length / this.itemsPerPage);
    },
    sortedItemProperty() {
      return this.itemProperties.find(
        (x) => x.propertyName === this.sort.property
      );
    },
    isSortAsc() {
      return this.sort.order === SORT_ORDER_ASC;
    },
    itemsToDisplay() {
      // Filter
      let result = [...this.items];
      if (
        this.searchValue &&
        this.searchVal !== '' &&
        !this.customSearch &&
        this.searchedFields &&
        this.searchedFields.length > 0 &&
        Array.isArray(this.items)
      ) {
        let splitSearchValue = (this.searchValue || '')
          .split(' ')
          .map((elt) => elt.toLowerCase());

        result = this.items.filter((item) => {
          return splitSearchValue.every((searchVal) => {
            return (
              getSearchableValues
                .bind(this, item)
                .call()
                .find((searchable) => {
                  return searchable.toLowerCase().includes(searchVal);
                }) !== undefined
            );
          });
        });
      }

      // Sort
      if (this.sortedItemProperty && !this.deferSorting) {
        let sortValueGetter = (item) => {
          let value = item[this.sortedItemProperty.propertyName];
          if (
            value !== null &&
            value !== undefined &&
            this.sortedItemProperty.sortProp
          ) {
            value = value[this.sortedItemProperty.sortProp];
          }
          return value;
        };
        result.sort((a, b) => {
          let valueA = sortValueGetter(a);
          let valueB = sortValueGetter(b);
          if (valueA > valueB) {
            return this.isSortAsc ? 1 : -1;
          } else if (valueA < valueB) {
            return this.isSortAsc ? -1 : 1;
          } else {
            return 0;
          }
        });
      }

      // Paginate
      if (this.paginator) {
        const startIndex = this.currentPage * this.itemsPerPage;
        const endIndex = (this.currentPage + 1) * this.itemsPerPage;
        result = result.slice(startIndex, endIndex);
      }

      return result;
    },
    tabLinesToDisplay() {
      return this.isLoading
        ? []
        : this.itemsToDisplay.map((itemToDisplay) => {
            return this.tabLines.find(
              (tabLine) => tabLine.item === itemToDisplay
            );
          });
    },
    tabLines() {
      return this.items.map((value) => {
        return {
          selected: this.selectedItems.some(
            (selItem) =>
              selItem[this.itemIdProperty] === value[this.itemIdProperty]
          ),
          editable:
            this.editingItemId === getItemIdentifier.bind(this, value).call(),
          item: value,
        };
      });
    },
    workInProgress() {
      return this.editingItemId !== null || this.newItem !== null;
    },
    hasSelectedItems() {
      return this.selectedItems.length > 0;
    },
    itemBeingCreated() {
      return this.newItem !== null && this.newItem !== undefined;
    },
    itemBeingEdited() {
      let tablineBeingEdited = this.tabLinesToDisplay.find(
        (tabLine) => tabLine.editable
      );
      if (tablineBeingEdited) {
        return tablineBeingEdited.item;
      } else {
        return null;
      }
    },
    wipHitForm() {
      let result = undefined;
      if (this.editingItemId) {
        result = this.$refs[currentHitFormRef(this.editingItemId)];
      } else if (this.newItem) {
        result = this.$refs[this.newHitFormRef];
      }
      if (Array.isArray(result)) {
        result = result[0];
      }
      return result;
    },
    addButtonVisible() {
      return (
        this.addable && (this.createItemFn != null || this.hasButtonAddSlot)
      );
    },
    addButtonDisabled() {
      return (
        this.isLoading ||
        this.workInProgress ||
        this.hasSelectedItems ||
        !this.addItemAllowed
      );
    },
    hasButtonAddSlot() {
      return !!this.$slots.buttonAdd;
    },
    visibleFields() {
      return this.itemPropertiesToDisplay.map(
        (x) => x.propertyQuery || x.propertyName
      );
    },

    /**
     * Activates the search bar when the search field is not set to null or undefined
     */
    searchable() {
      return this.searchField;
    },
    hasActiveFilters() {
      if (!this.userFilters) return false;
      return Object.keys(this.userFilters).length > 0;
    },
  },

  watch: {
    sort: {
      handler() {
        if (this.finishedMounting) {
          this.$emit('sort', {
            property: this.sortedItemProperty,
            asc: this.isSortAsc,
          });
        }
      },
      deep: true,
      immediate: true,
    },
    visibleFields: {
      handler(value) {
        this.$emit('update-visible-fields', value);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.finishedMounting = true;
  },
  beforeMount() {
    this.searchValue = this.initialSearchValue;
    if (this.disableHideColumns) return;
    let hiddenColumns = [];
    if (this.configStore) {
      const parentPanel = inject('panelId', null);
      const parentModal = inject('modalId', null);
      this.parentDomWrapper = parentModal || parentPanel;
      hiddenColumns = this.configStore.configuration[
        this.parentDomWrapper + '.columns'
      ];
      this.columnsToDisplay = [];
    }
    // The config is registered for the user, and he wants to not show all the columns
    if (hiddenColumns && hiddenColumns.length > 0) {
      this.itemProperties.forEach((prop) => {
        if (!hiddenColumns.includes(prop.propertyName)) {
          this.columnsToDisplay.push(prop.propertyName);
        }
      });
    }
    // Config not registered or empty list from old config logic
    else {
      hiddenColumns = this.getColumnsToHide();
      this.itemProperties.forEach((prop) => {
        if (!hiddenColumns.includes(prop.propertyName)) {
          this.columnsToDisplay.push(prop.propertyName);
        }
      });
    }
  },
  methods: {
    displayFilterConfigModal() {
      this.dataService
        .read('config', {
          attributes: 'value',
          filters: {
            label: 'eq.filter.preset',
            name: `eq.${this.parentDomWrapper}`,
            user_id: `eq.${this.userStore.keycloakId}`,
          },
        })
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            this.filterConfigData = res.data[0]?.value ?? [];
          } else {
            this.filterConfigData = [];
          }
        });
    },

    saveActualFilter() {
      if (
        this.filterConfigData.some(
          (item) => item.designation === this.filterSaveName
        )
      ) {
        this.filterErrorMessage = this.t(
          'hit-components.table.preset-filter-name-already-exists'
        );
        return;
      }
      let newFilterConfig = [];
      if (this.filterConfigData) {
        newFilterConfig = newFilterConfig.concat(this.filterConfigData);
      }
      newFilterConfig.push({
        designation: this.filterSaveName,
        value: this.userFilters,
      });
      if (this.filterConfigData.length > 0) {
        this.dataService
          .update(
            'config',
            {
              label: 'eq.filter.preset',
              name: `eq.${this.parentDomWrapper}`,
            },
            {value: newFilterConfig}
          )
          .then(() => {
            this.displayFilterConfigModal();
            this.filterSaveName = '';
          });
      } else {
        this.dataService
          .create('config', {
            id: HitUUIDUtils.generate(),
            label: 'filter.preset',
            user_id: this.userStore.keycloakId,
            name: this.parentDomWrapper,
            visible: false,
            customisable: true,
            datatype: 'JSON',
            value: newFilterConfig,
            global: false,
          })
          .then(() => {
            this.displayFilterConfigModal();
            this.filterSaveName = '';
          });
      }
    },

    deletePresetFilters(items) {
      this.filterConfigData = this.filterConfigData.filter(
        (filter) =>
          !items.some((item) => item.designation === filter.designation)
      );
      if (this.filterConfigData && this.filterConfigData.length > 0) {
        this.dataService
          .update(
            'config',
            {
              label: 'eq.filter.preset',
              name: `eq.${this.parentDomWrapper}`,
            },
            {value: this.filterConfigData}
          )
          .then(() => {})
          .catch(() => this.displayFilterConfigModal());
      } else {
        this.dataService
          .delete('config', {
            label: 'eq.filter.preset',
            name: `eq.${this.parentDomWrapper}`,
          })
          .then(() => {})
          .catch(() => this.displayFilterConfigModal());
      }
    },

    selectFilter(filter) {
      this.$emit('changeFilter', filter.value);
      this.filterConfigData = null;
    },

    searchValueChanged(newValue) {
      this.searchValue = newValue;
      this.$emit('search', newValue);
    },
    addNewItem() {
      this.newItem = this.createItemFn();
      if (this.newItem) {
        this.scrollToNewItem();
      }
      this.$emit('addButtonClicked');
    },
    selectItem(item) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item);
      }
      this.$emit('items-selection-changed', this.selectedItems.length);
    },
    unselectItem(unselectedItem) {
      // Impossible to use the filter method because it will introduce reference errors
      const index = this.selectedItems.findIndex(
        (item) =>
          item[this.itemIdProperty] === unselectedItem[this.itemIdProperty]
      );
      this.selectedItems.splice(index, 1);

      this.$emit('items-selection-changed', this.selectedItems.length);
    },
    toggleSelectItem(item) {
      const selected = this.selectedItems.some(
        (selItem) => selItem.id === item.id
      );
      selected ? this.unselectItem(item) : this.selectItem(item);
    },
    openInNewTab(item_id) {
      if (!this.routerLink) return;
      let params = {
        [this.itemIdProperty]: item_id,
      };

      if (this.originView) {
        params = {
          ...params,
          origin: this.originView,
          parentId: this.parentId,
          storeFilters: this.storeFilters,
        };
      }
      const routeData = this.$router.resolve({
        name: this.routerLink,
        params: {
          id: item_id,
        },
        query: this.routerQueryParams,
      });
      const a = document.createElement('a');
      a.href = routeData.fullPath;
      a.target = '_blank';
      const e = new MouseEvent('click', {
        ctrlKey: true, // for Windows or Linux
        metaKey: true, // for MacOS
      });
      a.dispatchEvent(e);
    },
    editItem(itemId) {
      if (this.rowClickToSelect) {
        this.toggleSelectItem({id: itemId});
        return;
      }
      if (this.editingItemId === itemId) {
        return;
      }

      this.requestingEditionItemId = null;
      if (!this.inlineEditionAllowed) {
        const selectedItem = this.items.filter(
          (item) => item?.[this.itemIdProperty] === itemId
        )[0];
        this.$emit('item-selected', selectedItem);
        if (this.routerLink) {
          let params = {
            [this.itemIdProperty]: itemId,
          };

          if (this.originView) {
            params = {
              ...params,
              origin: this.originView,
              parentId: this.parentId,
              storeFilters: this.storeFilters,
            };
          }
          this.$router.push({
            name: this.routerLink,
            params: params,
            query: this.routerQueryParams,
          });
        }
      } else {
        if (this.workInProgress) {
          if (this.wipHitForm && this.wipHitForm.pristine) {
            this.cancelWIP();
            this.editItem(itemId);
          } else {
            // this.scrollToCurrentItem();
            this.requestingEditionItemId = itemId;
            openWIPDialog.bind(this).call();
          }
        } else {
          const selectedItem = this.items.filter(
            (item) => item.id === itemId
          )[0];
          this.editingItemId = itemId;
          this.$emit('editing-item', itemId, selectedItem);
          // this.scrollToItem(this.editingItemId);
        }
      }
    },
    nextPage() {
      this.currentPage++;
    },
    previousPage() {
      this.currentPage--;
    },
    toggleSortOrder() {
      if (this.sort.order === SORT_ORDER_ASC) {
        this.sort.order = SORT_ORDER_DESC;
      } else {
        this.sort.order = SORT_ORDER_ASC;
      }
    },
    cancelWIP() {
      if (this.newItem) {
        this.disableCreating();
      } else if (this.editingItemId) {
        this.disableEditing();
      }
    },
    disableEditing() {
      this.editingItemId = null;
      this.$emit('editing-item', null);
    },
    disableCreating() {
      this.newItem = null;
    },
    deleteItems() {
      this.$emit('delete', this.selectedItems);
      this.resetSelectedItems();
    },
    shareItems() {
      this.$emit('share', this.selectedItems);
    },
    printItems() {
      this.$emit('print', this.selectedItems);
    },
    scrollToNewItem() {
      this.scrollTo('.hit-table-line-new-item');
    },
    scrollTo(selector) {
      this.$nextTick(() => {
        // Need to use $nextTick to call this method after the DOM has been updated.
        let element = this.$el.querySelector(selector);
        if (element) {
          element.scrollIntoView({block: 'nearest'});
        }
      });
    },
    updateWipColumnsToDisplay(checked, columnName) {
      this.wipColumnsToDisplay = this.wipColumnsToDisplay.filter(
        (x) => x !== columnName
      );
      if (checked) {
        this.wipColumnsToDisplay.push(columnName);
      }
    },
    openColumnsToDisplayDialog() {
      this.showColumnsToDisplay = true;
      this.wipColumnsToDisplay = Object.assign([], this.columnsToDisplay);
    },
    closeColumnsToDisplayDialog() {
      this.showColumnsToDisplay = false;
    },
    /**
     * Code executed when the user saves the set of columns he wants to display
     */
    columnsToDisplayDialogSave() {
      let dbHiddenColumns = [];
      let isDefaultConfig = true;
      this.itemProperties.forEach((prop) => {
        if (!this.wipColumnsToDisplay.includes(prop.propertyName)) {
          dbHiddenColumns.push(prop.propertyName);
        }
        if (
          (!!prop.hideByDefault &&
            this.wipColumnsToDisplay.includes(prop.propertyName)) ||
          (!prop.hideByDefault &&
            !this.wipColumnsToDisplay.includes(prop.propertyName))
        ) {
          isDefaultConfig = false;
        }
      });
      if (isDefaultConfig) {
        dbHiddenColumns = [];
      } else if (dbHiddenColumns.length === 0) {
        dbHiddenColumns.push(SHOW_ALL_COLUMNS_KEY);
      }
      this.configStore.updateHiddenConfiguration(
        this.parentDomWrapper + '.columns',
        dbHiddenColumns,
        isDefaultConfig
      );
      this.columnsToDisplay = Object.assign([], this.wipColumnsToDisplay);
      this.closeColumnsToDisplayDialog();
    },
    getColumnsToHide() {
      let columnsToHide = [];
      if (this.configStore) {
        let companyOtherLocales = this.configStore.companyLanguagesSnakeCase.slice(
          1
        );
        this.itemProperties.forEach((prop) => {
          if (
            companyOtherLocales.some((locale) =>
              prop.propertyName.includes(locale)
            ) ||
            prop.hideByDefault
          ) {
            columnsToHide.push(prop.propertyName);
          }
        });
      }
      return columnsToHide;
    },
    wipDialogCancel() {
      closeWIPDialog.bind(this).call();
      this.wipHitForm.cancelButtonAction();
      this.disableEditing();
      retryEditItem.bind(this).call();
    },
    wipDialogSave() {
      closeWIPDialog.bind(this).call();
      this.wipHitForm.saveButtonAction();
      this.disableEditing();
      retryEditItem.bind(this).call();
    },
    wipDialogKeepEditing() {
      closeWIPDialog.bind(this).call();
    },
    getLineClass(item, additionalLine) {
      let customClass = '';
      if (this.customLineClass) {
        if (typeof this.customLineClass === 'function') {
          customClass = this.customLineClass(item);
        } else {
          customClass = this.customLineClass;
        }
      }

      return `${
        getItemIdentifier.bind(this, item).call() ===
          getItemIdentifier
            .bind(this, lastTabLineItem.bind(this).call())
            .call() && !additionalLine
          ? 'last-hit-table-line-item'
          : ''
      } ${
        getItemIdentifier.bind(this, item).call() ===
        getItemIdentifier.bind(this, firstTabLineItem.bind(this).call()).call()
          ? 'first-hit-table-line-item'
          : ''
      } hit-table-line-item-${getItemIdentifier
        .bind(this, item)
        .call()} ${customClass}`;
    },
    notImplementedYet() {
      alert('Not implemented yet');
    },
    /**
     * We only want to reset the selected records when we know that the process was successfully
     * For printing reports -> we need to execute this method from the parent component when the API call was successfully
     */
    resetSelectedItems() {
      this.selectedItems = [];
    },
    getSelectedItems() {
      return this.selectedItems;
    },
    removeAllFilters() {
      this.$emit('changeFilter', {});
    },
  },
};
</script>

<style scoped lang="scss">
.hit-table-loading {
  min-height: 60px; /* enough place for spinner */
}

.hit-table-root {
  grid-template-areas:
    'hit-table-menu'
    'hit-table'
    'hit-table-footer';
  grid-template-rows: max-content 1fr max-content;
  @apply grid;
}

.hit-table-menu {
  grid-area: hit-table-menu;
  grid-template-columns: 1fr max-content;
  grid-template-areas: 'hit-table-menu-left hit-table-menu-right';
  @apply items-center gap-1 grid px-2 py-1;
}

.hit-table-menu-mobile {
  grid-area: hit-table-menu;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'hit-table-menu-left hit-table-menu-right'
    'hit-table-menu-bottom hit-table-menu-bottom';
  @apply grid gap-1 items-center p-1;
}

.hit-table-menu-mobile.only-one-row {
  grid-template-rows: 0 1fr;
}

.hit-table-menu-left {
  grid-template: 'hit-table-menu-left';
  @apply grid grid-flow-col gap-1 items-center;
}

.hit-table-menu-right {
  grid-template: 'hit-table-menu-right';
  @apply grid grid-flow-col gap-1 items-center;
}

.hit-table-footer {
  grid-template-columns: 1fr max-content;
  grid-template-areas: 'hit-table-footer-left hit-table-footer-right';
  grid-area: hit-table-footer;
  @apply grid gap-1 items-center px-2 pt-2 pb-2;
}

.hit-table-footer-left {
  grid-template: 'hit-table-footer-left';
  @apply flex gap-1 flex-wrap;
}

.hit-table-footer-right {
  grid-template: 'hit-table-footer-right';
  @apply text-right;
}

.hit-table-menu-bottom {
  grid-area: hit-table-menu-bottom;
  grid-template-columns: max-content 1fr max-content;
  @apply grid gap-1;
}
</style>
