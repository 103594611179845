import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import BaseModel from './BaseModel';
import {required} from '@vuelidate/validators';
export default class PriceIndex extends BaseModel {
  constructor({
                id,
                number,
                designation,
                memo = undefined,
              }) {
    super(id, number, designation);
    this.memo = memo;
  }
  static get config() {
    const config = BaseModel.config;
    config['browsable'] = false;
    return config;
  }
  get config() {
    return PriceIndex.config;
  }
  static get fkName() {
    return null;
  }
  static get apiRoute() {
    return 'standard_index';
  }
  static get listPageRoute() {
    return 'priceIndexList';
  }
  static get detailPageRoute() {
    return 'priceIndexDetail';
  }
  static get createInitColumns() {
    return {
      designation: '',
    };
  }
  get fkName() {
    return PriceIndex.fkName;
  }
  get apiRoute() {
    return PriceIndex.apiRoute;
  }
  get detailPageRoute() {
    return PriceIndex.detailPageRoute;
  }
  get listPageRoute() {
    return PriceIndex.listPageRoute;
  }
  get createInitColumns() {
    return PriceIndex.createInitColumns;
  }
  static get joinAttrsKey() {
    return [
      'number',
      'designation',
    ];
  }
  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
    ]);
  }
  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          mainSearch: true,
          maxWidth: '1fr',
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
    };
  }
}
