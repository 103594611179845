<template>
  <hit-select
    :full-width="true"
    :label-fields="['designation']"
    :label="t('hit-app.printer-configuration.select-template')"
    :options="options"
    :value="value"
    :adapt-to-content="false"
    :row-label="false"
    :clearable="false"
    @change="onChange"
  />
</template>

<script>
import {HitSelect} from '@hit/components';
import {useI18n} from 'vue-i18n';
import HitInputMixin from '@hit/components/src/mixins/form/HitInputMixin';
import {DataService} from '../../../../api';

export default {
  name: 'HitAppInputTemplate',
  components: {HitSelect},
  mixins: [HitInputMixin],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    templatePrefix: {
      type: String,
      required: false,
      default: 'form',
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      options: [],
    };
  },
  methods: {
    onChange(value) {
      this.fireInputChange(value);
    },

    /**
     * Each company can define his own templates -> load from db the available templates
     */
    loadCustomTemplates() {
      DataService.read('document', {
        attributes: 'id,designation',
        filters: {
          folder_name: 'eq.config',
          filename: 'like.*.jinja*',
        },
      }).then((res) => {
        if (res && res.data && res.data.length > 0) {
          res.data.forEach((option) => this.options.push(option));
        }
      });
    },
  },
  beforeMount() {
    this.loadCustomTemplates();
    switch (this.templatePrefix) {
      case 'form':
        this.options.push({
          id: 'formDefault',
          designation: this.t('hit-app.form.default-template'),
        });
        break;
      case 'transaction':
        this.options.push({
          id: 'transactionDefault',
          designation: this.t('hit-app.transaction.print-as-invoice'),
        });
        this.options.push({
          id: 'transactionQuotation',
          designation: this.t('hit-app.transaction.print-as-quotation'),
        });
        this.options.push({
          id: 'transactionBillOfQuantities',
          designation: this.t('hit-app.transaction.bill-of-quantity'),
        });
        break;
      case 'periodicBill':
        this.options.push({
          id: 'periodicBillDefault',
          designation: this.t('hit-app.project.project-progress-statement'),
        });
        this.options.push({
          id: 'periodicBillMeasurementSheetDefault',
          designation: this.t('hit-app.project.measurement-sheet'),
        });
        break;
      case 'projectPart':
        this.options.push({
          id: 'projectPartQuotation',
          designation: this.t('hit-app.transaction.print-as-quotation'),
        });
        this.options.push({
          id: 'projectPartBillOfQuantities',
          designation: this.t('hit-app.transaction.bill-of-quantity'),
        });
        this.options.push({
          id: 'projectPartCostSummary',
          designation: this.t('hit-app.common.cost-summary'),
        });
        this.options.push({
          id: 'projectPartCostDetails',
          designation: this.t('hit-app.common.cost-details'),
        });
        break;
      case 'priceRequest':
        this.options.push({
          id: 'purchasePriceRequestSupplier',
          designation: this.t(
            'hit-app.hit-purchase-document-type.price-request'
          ),
        });
        break;
      case 'actionSummary':
        this.options.push({
          id: 'actionSummary',
          designation: this.t('hit-app.action.action-summary'),
        });
        break;
      case 'purchase':
        this.options.push({
          id: 'purchaseOrder',
          designation: this.t('hit-app.hit-purchase-document-type.purchase-order'),
        });
        this.options.push({
          id: 'purchasePriceComparisonSupplier',
          designation: this.t('hit-app.price-request.supp-price-comparison'),
        });
        break;
      case 'equipment':
        this.options.push({
          id: 'equipmentCostSummary',
          designation: this.t('hit-app.common.cost-summary'),
        });
        this.options.push({
          id: 'equipmentCostDetails',
          designation: this.t('hit-app.common.cost-details'),
        });
        break;
      case 'staff':
        this.options.push({
          id: 'staffCostSummary',
          designation: this.t('hit-app.common.cost-summary'),
        });
        this.options.push({
          id: 'staffCostDetails',
          designation: this.t('hit-app.common.cost-details'),
        });
        break;

    }
    // TODO: load available templates from backend
    if (!this.value) {
      this.onChange(this.options[0].id);
    }
  },
};
</script>
