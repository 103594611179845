import {BaseModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {$d} from '@hit/base/src/plugins/i18n/i18n';

export default class Period extends BaseModel {
  constructor({
    id,
    number,
    designation,
    active = true,
    beginDate = undefined,
    endDate = undefined,
    closed = false,
  }) {
    super(id, number, designation);
    this.active = active;
    this.beginDate = beginDate;
    this.endDate = endDate;
    this.closed = closed;
  }

  static get fkName() {
    return 'period_id';
  }
  static get apiRoute() {
    return 'period';
  }
  static get detailPageRoute() {
    return 'periodList';
  }
  static get listPageRoute() {
    return 'periodList';
  }
  get fkName() {
    return Period.fkName;
  }
  get apiRoute() {
    return Period.apiRoute;
  }
  get listPageRoute() {
    return Period.listPageRoute;
  }
  get detailPageRoute() {
    return Period.detailPageRoute;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'beginDate', 'endDate'];
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      ['number', 'designation', 'beginDate', 'endDate'],
      []
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          mainSearch: true,
          maxWidth: '1fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '3.5rem',
        },
      }),
      beginDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'begin_date',
        tableProperties: {
          header: 'hit-app.common.begin-date',
          maxWidth: '1fr',
        },
      }),
      endDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'end_date',
        tableProperties: {
          header: 'hit-app.common.end-date',
          maxWidth: '1fr',
        },
      }),
      closed: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.closed',
        },
      }),
    };
  }
}
