<template>
  <hit-modal
    v-if="!previewFileBuffer"
    :title="t('hit-app.common.printer-settings')"
    prefix-icon="printer"
    popup-size="small"
    @clickOutside="emitCancel"
  >
    <div class="flex flex-col space-y-4">
      <div v-if="chooseTemplate">
        <hit-app-input-template
          :value="selectedTemplate"
          :template-prefix="templateType"
          @change="(value) => (selectedTemplate = value)"
        />
      </div>
      <div v-if="chooseLayout">
        <hit-app-input-print-layout
          :value="selectedPrintLayout"
          @change="(value) => (selectedPrintLayout = value)"
        />
      </div>
      <div v-if="choosePrintLanguage">
        <hit-app-input-print-language
          :value="selectedPrintLanguage"
          @change="(value) => (selectedPrintLanguage = value)"
        />
      </div>
      <div v-if="chooseOneOrMultipleFiles">
        <hit-input-radio-button
          :options="fileOptions"
          :value="fileOptionValue"
          @change="(v) => (fileOptionValue = v)"
        />
      </div>
      <div v-if="customConfigs.length > 0" class="w-full hit-form">
        <div
          v-for="config in customConfigs"
          :key="config.id"
          class="w-full check-full-width"
        >
          <hit-input-checkbox
            v-if="config.type === 'boolean'"
            :values="t(config.label)"
            :value="config.default"
            inline-input
            @change="(v) => changeConfigValue(config.id, v)"
          />
          <hit-input
            v-else
            :label="t(config.label)"
            :type="config.type"
            :value="config.default"
            inline-input
            @change="(v) => changeConfigValue(config.id, v)"
          />
        </div>
      </div>
      <div class="flex flex-row justify-between">
        <div>
          <hit-button
            :label="t('hit-app.printer-configuration.set-as-default')"
            color="accent"
            prefix-icon="save"
            @click="setAsDefault"
          />
        </div>
        <div class="flex flex-wrap justify-end space-x-2">
          <div>
            <hit-button
              :label="t('hit-components.common.cancel')"
              :disabled="isPrinting"
              color="button"
              prefix-icon="clear"
              @click="emitCancel"
            />
          </div>
          <div>
            <hit-button
              :label="t('hit-app.common.preview')"
              color="accent"
              prefix-icon="preview"
              :disabled="fileOptionValue === 'multiple-files' || isPrinting"
              :show-spinner="isPrintingForPreview"
              @click="displayPreviewModal"
            />
          </div>
          <div>
            <hit-button
              :disabled="readyToPrint || isPrintingForPreview"
              :label="t('hit-base.common.print')"
              color="accent"
              prefix-icon="check"
              :show-spinner="isPrinting && !isPrintingForPreview"
              @click="printFiles"
            />
          </div>
        </div>
      </div>
    </div>
  </hit-modal>
  <hit-pdf-preview-modal
    v-else
    :pdf-file-buffer="previewFileBuffer"
    :pdf-file-name="previewFileName"
    @click-outside="handleClosePreview"
  />
</template>

<script>
import {
  HitButton,
  HitIcon,
  HitInput,
  HitInputCheckbox,
  HitInputRadioButton,
  HitModal,
  HitUUIDUtils,
} from '@hit/components';
import HitAppInputPrintLayout from '@hit/base/src/components/hit/form/input/HitAppInputPrintLayout.vue';
import HitAppInputPrintLanguage from '@hit/base/src/components/hit/form/input/HitAppInputPrintLanguage.vue';
import {useI18n} from 'vue-i18n';
import HitAppInputTemplate from '@hit/base/src/components/hit/form/input/HitAppInputTemplate.vue';
import {
  ActionService,
  DataService,
  useNotificationsStore,
  useConfigurationStore,
} from '@hit/base';
import {HitBannerNotificationItem} from '@hit/base/src/store/pinia/notifications';
import HitSpinner from '@hit/components/src/components/icon/HitSpinner.vue';
import {PrintConfigService} from './PrintConfigService';
import HitPdfPreviewModal from '../../hit/modal/HitPdfPreviewModal.vue';

export default {
  name: 'HitAppPrintConfiguration',
  components: {
    HitIcon,
    HitModal,
    HitAppInputPrintLanguage,
    HitAppInputPrintLayout,
    HitButton,
    HitAppInputTemplate,
    HitInputRadioButton,
    HitSpinner,
    HitInput,
    HitPdfPreviewModal,
    HitInputCheckbox,
  },
  setup() {
    const {t} = useI18n();
    const config = useConfigurationStore();
    return {t, config};
  },
  props: {
    chooseTemplate: {
      type: Boolean,
      default: true,
    },
    chooseLayout: {
      type: Boolean,
      default: true,
    },

    /**
     * Defines the templates the user can select from(form, transaction, etc.)
     */
    templateType: {
      type: String,
      required: false,
      default: 'form',
    },

    /**
     * Array of the UUIDs we want to print
     */
    idsToPrint: {
      type: [String, Array],
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      isPrinting: false,
      isPrintingForPreview: false,
      configs: {},
      selectedTemplate: null,
      selectedPrintLayout: null,
      selectedPrintLanguage: null,
      previewFileBuffer: null,
      previewFileName: null,
      fileOptions: [
        {
          label: this.t('hit-app.printer-configuration.single-file'),
          id: 'single-file',
        },
        {
          label: this.t('hit-app.printer-configuration.multiple-files'),
          id: 'multiple-files',
        },
      ],
      fileOptionValue: 'single-file',
    };
  },
  computed: {
    /**
     * The choice between one single file or multiple files can only be done when
     * the user selected multiple files to print.
     */
    chooseOneOrMultipleFiles() {
      return Array.isArray(this.idsToPrint) && this.idsToPrint.length > 1;
    },

    /**
     * In soe cases, we can choose the language the pdf should be printed in
     */
    choosePrintLanguage() {
      return ['form'].includes(this.templateType);
    },

    /**
     * Following the type of report, we use different routes to generate the data
     */
    reportActionRoute() {
      switch (this.templateType) {
        case 'form':
          return 'report/form';
        case 'transaction':
          return 'report/transaction';
        case 'periodicBill':
          if (this.selectedTemplate === 'periodicBillDefault') {
            return 'report/periodic-bill';
          } else {
            return 'report/periodic-bill-measurement-sheet';
          }
        case 'projectPart':
          if (this.selectedTemplate === 'projectPartCostSummary') {
            return 'report/project-part-cost-summary';
          } else if (this.selectedTemplate === 'projectPartCostDetails') {
              return 'report/project-part-cost-details';
          } else {
            return 'report/project-part';
          }
        case 'priceRequest':
          return 'report/price-request';
        case 'actionSummary':
          return 'report/action-summary';
        case 'purchase':
          if (this.selectedTemplate === 'purchaseOrder') {
            return 'report/purchase-order';
          } else {
            return 'report/purchase-supplier-price-comparison';
          }
        case 'equipment':
          if (this.selectedTemplate === 'equipmentCostSummary') {
            return 'report/equipment-cost-summary';
          } else {
            return 'report/equipment-cost-details';
          }
        case 'staff':
          if (this.selectedTemplate === 'staffCostSummary') {
            return 'report/staff-cost-summary';
          } else {
            return 'report/staff-cost-details';
          }
        default:
          throw Error('Unsupported template type for the print config');
      }
    },

    /**
     * Flag that checks if all the needed values have been selected -> enables print button
     */
    readyToPrint() {
      return !(
        (!this.chooseTemplate || this.selectedTemplate) &&
        (!this.chooseLayout || this.selectedPrintLayout) &&
        (!this.chooseLanguage || this.selectedPrintLanguage)
      );
    },

    /**
     * Returns a list of custom configs that need to be displayed in the print
     * configuration modal
     */
    customConfigs() {
      return PrintConfigService.getTemplateConfigs(this.selectedTemplate);
    },
  },
  methods: {
    /**
     * Initialises the config variable with the default values
     */
    initConfigs() {
      this.customConfigs.forEach((config) => {
        this.configs[config.id] = config.default;
      });
    },

    /**
     * Updates the value of changed configuration in the configs dictionary
     */
    changeConfigValue(configId, value) {
      this.configs[configId] = value;
    },

    /**
     * Method executed when the user clicks on the print button in the modal.
     * During the print process, we disable buttons and cancel and display spinner
     */
    async printFiles(downloadPdf = true) {
      this.isPrinting = true;
      let file = undefined;
      switch (this.fileOptionValue) {
        case 'single-file':
          file = await this.printInSingleFile(downloadPdf);
          break;
        case 'multiple-files':
          await this.printInMultipleFiles(downloadPdf);
          break;
      }
      this.isPrinting = false;
      if (downloadPdf) {
        this.$emit('printed');
      } else {
        return file;
      }
    },

    /**
     * Prints all the selected files in one single PDF
     */
    async printInSingleFile(downloadPdf = true) {
      return await this.printFile(this.idsToPrint, downloadPdf);
    },

    /**
     * Uses one PDF per item to print. For each does not work for async operations
     */
    async printInMultipleFiles(downloadPdf = true) {
      for (const item of this.idsToPrint) {
        await this.printFile([item], downloadPdf);
      }
    },

    /**
     * Makes the call to the action API (where data is processed) and then calls
     * report API to print the list of records
     */
    async printFile(items, downloadPdf = true) {
      return await ActionService.post(this.reportActionRoute, {
        values: {
          itemsToPrint: items,
          templateId: this.selectedTemplate,
          pageLayout: this.selectedPrintLayout,
          preferredLanguage: this.selectedPrintLanguage,
          templateConfigs: this.configs,
        },
        config: {
          responseType: 'arraybuffer',
        },
        downloadAsPdf: downloadPdf,
      });
    },

    /**
     * Creates or updates the config record in the database for the selected template
     */
    async setAsDefault() {
      // We can not use upsert for the config table
      await DataService.delete('config', {
        label: 'eq.report.config',
        name: `eq.${this.selectedTemplate}`,
      });
      DataService.upsert('config', {
        id: HitUUIDUtils.generate(),
        label: 'report.config',
        name: this.selectedTemplate,
        visible: false,
        customisable: false,
        datatype: 'JSON',
        value: {
          layout_id: this.selectedPrintLayout,
        },
        global: false,
      }).then(() =>
        useNotificationsStore().insertBannerNotification(
          this.t('hit-base.banners.set-print-config'),
          HitBannerNotificationItem.SUCCESS
        )
      );
    },

    /**
     * We handle the cancel event differently when the report is being printed or not
     */
    emitCancel() {
      if (!this.isPrinting) {
        this.$emit('cancel');
      }
    },

    /**
     * Displays the printed PDF in the preview component
     */
    async displayPreviewModal() {
      this.isPrintingForPreview = true;
      const request = await this.printFiles(false);
      const contentDisposition = request.headers['content-disposition'];
      const filenameMatch = contentDisposition.match(
        /filename=['"]?([^'"]+)['"]?/
      );
      this.previewFileName =
        filenameMatch && filenameMatch[1] ? filenameMatch[1] : 'Hit-Online.pdf';
      this.previewFileBuffer = request.data;
    },

    /**
     * When the user closes the preview file, we also close the print modal
     * In fact, if he wants to download the file, he can do it via the viewer toolbar
     */
    handleClosePreview() {
      this.previewFileBuffer = null;
      this.$emit('printed');
      this.isPrintingForPreview = false;
    },
  },
  watch: {
    selectedTemplate(newValue) {
      this.initConfigs();
      DataService.read('config', {
        attributes: 'value',
        filters: {
          label: 'eq.report.config',
          name: `eq.${newValue}`,
        },
      }).then((res) => {
        if (res && res.data && res.data.length > 0) {
          this.selectedPrintLayout = res.data[0].value.layout_id;
        }
      });
    },
  },
};
</script>
