import {
  Address,
  Country, DueDate,
  Equipment,
  Project,
  ProjectPart,
  Qualification,
  Resource,
  ResourceCategory,
  ResourceData,
  ResourcePackaging,
  ResourceSupplier,
  Staff,
  Unit
} from '../../models';
import Stock from '../../models/hit/Stock';
import StandardText from '../../models/hit/StandardText';
import PriceIndex from '../../models/hit/PriceIndex';
import PriceIndexData from '../../models/hit/PriceIndexData';
export default {
  computed: {
    Address() {
      return Address;
    },
    Country() {
      return Country;
    },
    DueDate () {
      return DueDate;
    },
    Equipment() {
      return Equipment;
    },
    Project() {
      return Project;
    },
    ProjectPart() {
      return ProjectPart;
    },
    Resource() {
      return Resource;
    },
    ResourceCategory() {
      return ResourceCategory;
    },
    ResourceData() {
      return ResourceData;
    },
    ResourcePackaging() {
      return ResourcePackaging;
    },
    ResourceSupplier() {
      return ResourceSupplier;
    },
    Staff() {
      return Staff;
    },
    StandardText () {
      return StandardText;
    },
    Stock () {
      return Stock;
    },
    Unit() {
      return Unit;
    },
    Qualification() {
      return Qualification;
    },
    PriceIndex() {
      return PriceIndex;
    },
  },
};
